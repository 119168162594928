<template>
  <span>
    <router-link :to="routerTo">
      <div class="tag-control">
        <b-taglist attached class="tags-custom">
          <!-- kode dibawah perlu menggunakan span manual, karena b-tag langsung meng-insert tag span di dalamnya -->
          <span class="tag is-grey">
            <span v-if="aktivitas.verifikasi === 'Belum'" class="icon has-text-light"></span>
            <b-icon
              v-else
              :type="tagType"
              size="is-small"
              pack="fas"
              :icon="getIcon(aktivitas.verifikasi)"
            ></b-icon>
          </span>
          <b-tag :class="bgColorClass" class="has-text-light">{{ aktKomp.nama | truncate }}</b-tag>
        </b-taglist>
      </div>
    </router-link>
  </span>
</template>


<script>
import { truncate } from "@/apps/core/modules/filters";
import { mapState } from "vuex";

const iconMap = {
  Ya: "check",
  Tidak: "times"
};

export default {
  name: "KomponenAktivitasTag",
  props: {
    id: String,
    namedUrl: String,
    aktivitas: Object,
    aktKomp: Object,
    keterampilan: { type: Boolean, default: false }
  },
  data() {
    return {
      routerTo: { name: this.namedUrl, params: { id: this.id } }
    };
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    tagType() {
      return this.aktivitas.verifikasi === "Tidak" ? "is-danger" : "is-success";
    },
    isVerified() {
      return this.aktivitas.verifikasi === "Ya"; // Jangan dihardcode, perlu simpan di APP_CONFIG
    },
    bgColorClass() {
      if (
        this.aktivitas.preceptor_id == this.userId ||
        this.aktivitas.pspd_id == this.userId
      ) {
        return "is-success";
      }
      return "is-info";
    }
  },
  filters: {
    truncate
  },
  methods: {
    getIcon(verifikasi) {
      return iconMap[verifikasi];
    }
  }
};
</script>


<style scoped lang="scss">
.tags {
  .tag:first-child {
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
  }

  .is-mandiri {
    background-color: #000000d1 !important;
  }

  .is-supervisi {
    background-color: #a905b3c2 !important;
  }

  .is-observasi {
    background-color: #f98912d6 !important;
  }

  .is-grey {
    background-color: #eae7e7 !important;
  }

  .is-grey-res {
    color: white !important;
    background-color: #6d6d6d !important;
  }

  /* diperlukan karena jika b-icon tidak diberikan is-small
     maka iconnya otomatis ditambahkan fa-lg */
  .icon.is-small {
    width: 1.5rem !important;
  }
}

.tags-custom {
  flex-wrap: unset !important;
  align-items: unset !important;
}

</style>
